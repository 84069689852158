import PropTypes from 'prop-types';

const propTypes = {
  aspectRatio: PropTypes.oneOf(['inherit', '1:1', '16:9']),
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  file: PropTypes.string,
  image: PropTypes.string,
  onAdPlay: PropTypes.func,
  onAdResume: PropTypes.func,
  onEnterFullScreen: PropTypes.func,
  onExitFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  onUnmute: PropTypes.func,
  onAutoStart: PropTypes.func,
  onResume: PropTypes.func,
  onPlay: PropTypes.func,
  generatePrerollUrl: PropTypes.func,
  onError: PropTypes.func,
  playerId: PropTypes.string.isRequired,
  playlist: PropTypes.array,
  onReady: PropTypes.func,
  onAdPause: PropTypes.func,
  onPause: PropTypes.func,
  onVideoLoad: PropTypes.func,
  playerScript: PropTypes.string.isRequired,
  onOneHundredPercent: PropTypes.func,
  onThreeSeconds: PropTypes.func,
  onTenSeconds: PropTypes.func,
  onThirtySeconds: PropTypes.func,
  onTwentyFivePercent: PropTypes.func,
  onFiftyPercent: PropTypes.func,
  onSeventyFivePercent: PropTypes.func,
  onNinetyFivePercent: PropTypes.func,
  onPlayAttemptFailed: PropTypes.func,
  onPositionChange: PropTypes.func,
  isMuted: PropTypes.bool,
  isAutoPlay: PropTypes.bool,
  gaCategory: PropTypes.string,
  gaUuid: PropTypes.string,
  gaInterval: PropTypes.bool,
  gaElapsed: PropTypes.bool,
  onPlayerLoaded: PropTypes.func,
  muxKey: PropTypes.string,
  muxPlayer: PropTypes.string,
  muxType: PropTypes.string,
  allowCast: PropTypes.bool,
  displaytitle: PropTypes.bool
};

export default propTypes;
