import React, {Component } from 'react'
import PropTypes from 'prop-types'
// import ImageSelector from '../ImageSelector'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import {JW_PLAYER_SRC, stripFormatting} from '../../util'

class LiveStream extends Component {
  constructor() {
    super()
  }

  render() {
    let stream = this.props.stream

    if(! stream.isLive()) {
      return <div className="">Not live</div>
    }
    const playerQueue = [{
      file: stream.stream_links.broadband_standard_dynamics,
      title: stripFormatting(stream.title),
      _gaId: `L-${this.props.stream.uuid}`,
      _gaTitle: stripFormatting(stream.title)
    }]
    return (
      <ReactJWPlayer
                  playerId="live-stream-video-player"
                  isAutoPlay={false}
                  aspectRatio="16:9"
                  playerScript={JW_PLAYER_SRC}
                  file=""
                  gaCategory="Media"
                  gaInterval={true}
                  gaElapsed={true}
                  playlist={playerQueue}
                  muxKey={process.env.REACT_APP_MUX_KEY}
                  muxPlayer="Live Stream"
                  muxType="live"
                  />
    )
  }
}

LiveStream.propTypes = {
  stream: PropTypes.object
}

export default LiveStream