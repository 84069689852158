function beforeComplete(event) {
  this.fireGa('Finished')
  this.props.onOneHundredPercent(event);

  if(this.props.gaElapsed) {
    this.fireGa('Elapsed')
  }

}

export default beforeComplete;
