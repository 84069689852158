import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from '../SvgIcon'
import playIcon from '../../../images/icons/24/play.svg'
import { Link } from 'react-router-dom'
import ReactJWPlayer from '../../react-jw-player/react-jw-player'
import ImageSelector from '../ImageSelector'
import MediaPlayer from '../MediaPlayer'
import LoadingIndicator from '../LoadingIndicator'
import moment from 'moment'
import {formatDuration, JW_PLAYER_SRC, getImageSizeInstance, stripFormatting} from '../../util'
import classnames from 'classnames'

class CompositionVideo extends MediaPlayer {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    if(! this.state.playing) {
      this.props.loadPerformanceVideo(this.props.performance)
      this.props.pauseAudio()
      this.setState({
        playing: true
      })
    }
  }

  playerId() {
    return `perf-video-${this.props.performance.id}`
  }

  render() {
    const recording = this.props.performance.videoRecording()
    const vtt_url = this.props.performance.queuepointVtt(recording)
    let tracks = vtt_url ? [{
          file: vtt_url,
          kind: 'chapters'
    }] : []

    let captionsNote
    const captionsUrl = this.props.performance.captionsUrl(recording)
    if(captionsUrl) {
      tracks.push({
              file: captionsUrl,
              label: "English",
              kind: "captions",
              "default": true
          })
          captionsNote = <div className="captions_note">This performance is offered with optional English subtitles. To enable or disable subtitles, use the <strong>CC</strong> button.</div>
    }

    let videoPlayer, productionCredit
    if(recording.production_credits) {
      const creditsLineBreaks = recording.production_credits.replace(/\n\r?/g, '<br />')
      productionCredit = <div className="credit" dangerouslySetInnerHTML={{__html: creditsLineBreaks}} />
    }

    if (this.props.showPlayerByDefault && this.props.signedIn && ! this.props.videoLoaded) {
      this.props.loadPerformanceVideo(this.props.performance)
      videoPlayer =  <LoadingIndicator margin="70px" />;
    }
    // Do not remove player if player is already loaded (eg in a log out)
    else if (this.state.playerLoaded || (this.state.playing && this.props.videoLoaded) || (this.props.showPlayerByDefault && this.props.signedIn && this.props.videoLoaded) ){
      let playerQueue = [{
        file: this.props.performance.video_stream_links.broadband_standard_dynamics,
        title: this.props.composition.title,
        _gaId: `V-${recording.uuid}`,
        _gaTitle: `${this.props.composition.alternative_formatted_title} / ${this.props.composition.composer.first_name} ${this.props.composition.composer.last_name}`,
        _gaProgram: `${stripFormatting(this.props.performance.program.title)} (${this.props.performance.dateDisplay()})`,
        tracks: tracks
      }]

      // Need a poster image in case of showing <video> by default
      if (this.props.showPlayerByDefault) {
        const image_obj = getImageSizeInstance(recording.preferred_image, "wd_512x288")
        if(image_obj) {
          playerQueue[0].image = image_obj.url
        }
      }

      const videoClasses = classnames(
        {
          ios_video: process.env.REACT_APP_USE_NATIVE_PLAYER,
          android_video: process.env.REACT_APP_ANDROID,
          video_player: true
        }
      )

      videoPlayer = (
          <div className={videoClasses}>
            <ReactJWPlayer
                        playerId={this.playerId()}
                        isAutoPlay={!this.props.showPlayerByDefault}
                        aspectRatio="16:9"
                        gaCategory="Media"
                        playerScript={JW_PLAYER_SRC}
                        onReady={this.onReady}
                        file=""
                        onPlayerLoaded={this.onPlayerLoaded}
                        onPlay={this.onPlay}
                        gaElapsed={true}
                        onEnterFullScreen={this.onEnterFullScreen}
                        onExitFullScreen={this.onExitFullScreen}
                        muxKey={process.env.REACT_APP_MUX_KEY}
                        muxPlayer="Composition Video"
                        muxType="on-demand"
                        playlist={playerQueue} />
          {captionsNote}
          {productionCredit}
          </div>
      )
    }
    else {
      const timeFormatted = formatDuration(moment.duration(recording.duration_ms))
      let errorMessage

      if(this.props.videoError === 'user') {
        errorMessage = <div className="error">
          <p>You must be signed in to view this video.</p>
          <Link to="/sign-in" className="button small">Sign in</Link>
          <br />
          <Link to="/sign-up" className="button small">Create a Free Account</Link>
        </div>
      }
      else if(this.props.videoError === 'rights') {
        errorMessage = <div className="error">
          We’re sorry, but this recording is not currently available in your location.
        </div>
      }
      const playButton =  (
        <div className="play_icon">
          <SvgIcon className="play" icon={playIcon} alt="Play" height="24" width="24" fill="#ffffff" />
        </div>
      )
      const thumbnailClasses = classnames(
        {
          fetch_error: this.props.videoError,
          row: true,
          video_preview: true
        }
      )
      videoPlayer = (
       <div className={thumbnailClasses}>
          <div className="columns medium-6 small-12">
            <div className="video_thumbnail" onClick={this.onClick} >
              <ImageSelector imageList={recording.preferred_image} size="wd_512x288" />
              <div className="overlay">
                {playButton}
              </div>
            </div>
            {errorMessage}
          </div>
          <div className="columns medium-6 small-12">
            <strong className="watch_link" onClick={this.onClick}>Watch Performance</strong>
            <br/>
            <span className="time">{timeFormatted}</span>
            {productionCredit}
        </div>
      </div>
      )
    }


    return (
      <div className="composition_video">
        {videoPlayer}
      </div>
    )
  }

}

CompositionVideo.propTypes = {
  performance: PropTypes.object,
  composition: PropTypes.object,
  videoLoaded: PropTypes.bool,
  loadPerformanceVideo: PropTypes.func,
  pauseAudio: PropTypes.func,
  videoError: PropTypes.string,
  signedIn: PropTypes.bool,
  showPlayerByDefault: PropTypes.bool
}

export default CompositionVideo