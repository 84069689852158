function fireGa(eventName) {
  const eventProps = {
    'category': this.props.gaCategory,
    'id': this.getGaAttribute('id'),
    'title': this.getGaAttribute('title'),
    'program': this.getGaAttribute('program')
  }
  if(eventName == 'Elapsed') {
    const { elapsedStart } = this.state
    eventProps.time = (new Date() - elapsedStart) / 1000
    if(eventProps.time < 10) {
      return false
    }
  }
  window.gtag('event', eventName, eventProps)
  if(window.fbq) {
    window.fbq('trackCustom', eventName, eventProps)
  }
}

export default fireGa;